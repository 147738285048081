<template>
  <v-row class="add-client-form no-padding pa-3">
    <FullscreenLoader v-if="loader" />
    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal()"
    ></successModal>
    <div class="col-md-12 no-padding" v-if="!showOverlay">
      <div class="row blue-underline mb-4 margin-l-11">
        <div class="col-md-12 mt-2 mb-2" v-if="itemtab == 'tab0'">
          <h3 class="form-title font ml-2 font-18">Location - General</h3>
        </div>
        <div class="col-md-12 row" v-if="itemtab == 'tab1'">
          <div class="col-xl-2 col-lg-4 col-md-4 mt-2">
            <h3 class="form-title font ml-2 font-18">Location - Providers</h3>
          </div>
          <div class="col-md-3">
            <v-text-field
              class="input-control mr-5"
              prepend-inner-icon="mdi-magnify"
              light
              dense
              hide-details
              outlined
              @input="searchProviders"
              placeholder="Search Providers"
            ></v-text-field>
          </div>
          <div class="col-xl-7 col-lg-5 col-md-5 text-end">
            <v-btn @click="openProviderDialog" class="my-1 mr-2 pl-5 pr-5 gray-border-buttons">
              <img class="pointer mr-1"  src="/svg/add.svg"/>

              Add</v-btn>
          </div>
        </div>
        <div class="col-md-12 mt-2 mb-2" v-if="itemtab == 'tab2'">
          <div class="row col-md-12 pb-0 justify-space-between">
            <h3 class="form-title font ml-2 font-18">Location - Personnel</h3>
            <div class="text-end">
              <v-btn @click="openPersonnelDialog" class="my-1 mr-2 pl-5 pr-5 gray-border-buttons">
                <img class="pointer mr-1"  src="/svg/add.svg"/>

                Add</v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <v-tabs dark show-arrows style="background: white" v-model.trim="tab">
          <v-tabs-slider style="background: #f6a405"></v-tabs-slider>
          <v-tab style="background: #4072b7; text-transform: none"
            v-for="(item, i) in items" :key="i"
            class="col-md-3 pl-1 pr-1"
            :href="'#tab' + i"
            :style="i == 1 ? { 'margin-right': '5px', 'margin-left': '5px' } : ''">
            <div class="mr-1 ml-1">
              {{ item.text }}
            </div>
          </v-tab>
        </v-tabs>
        <div class="col-md-12 contract-form" v-if="itemtab == 'tab0'">
          <v-form v-model.trim="valid" ref="locationRef" class="row">
            <div class="col-lg-6 col-xl-6 col-md-12">
              <div class="col-md-12 pb-0">
                <label class="font" for="">Location Name</label>
                <v-text-field
                  required
                  v-model.trim="location.name"
                  :rules="requiredRules"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pb-0">
                <label class="font" for="">NPI Number </label>
                <v-text-field
                  type="number"
                  v-model.trim="location.NPI"
                  :rules="location.NPI ? npiRules : []"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pb-1 pt-0">
                <label class="font text-gray" for="">Address 1</label>
               
                <v-autocomplete
                    prepend-inner-icon="mdi-map-marker"
                    style="background-color: white;"
                    v-model="placeId"
                    @update:search-input="delayFetchAddress"
                    @change="fetchPlace"
                    type="text"
                    :items="addressResults"
                    placeholder="Search Address"
                    solo
                    no-data-text="Please type address"
                    item-text="title"
                    item-value="value"
                    no-filter="true"
                ></v-autocomplete> 
<!-- 
                <v-text-field
                v-model="location.address1"
                id="pac-input"
                solo
                style="background-color: white;"
                ref="pacInput"
              ></v-text-field> -->
              </div>
              <div class="col-md-12 pb-0 pt-0">
                <label class="font text-gray" for="">Address 2</label>
                <v-text-field
                  required
                  solo
                  v-model.trim="location.address2"
                ></v-text-field>
              </div>
              <div class="col-md-12">
                <label class="font text-gray" for="">City</label>
                <v-text-field
                  solo
                  :rules="requiredRules"
                  v-model.trim="location.city"
                ></v-text-field>
              </div>
              <div class="row no-padding justify-space-between">
                <div class="col-md-4 pb-0 pt-0">
                  <label class="font text-gray" for="">State  </label>
                  <v-select
                    v-if="location.state && location.state.length > 2"
                    :rules="requiredRules"
                    :items="states"
                    v-model.trim="location.state"
                    return-object
                    item-text="name"
                    item-value="value"
                    solo>
                </v-select>
                  <v-select
                    v-else-if="location.state && location.state.length == 2"
                    :rules="requiredRules"
                    :items="states"
                    v-model.trim="location.state"
                    return-object
                    item-text="name"
                    item-value="value"
                    solo>
                  </v-select>
                  <v-select
                    v-else
                    :rules="requiredRules"
                    :items="states"
                    v-model.trim="location.state"
                    return-object
                    item-text="name"
                    item-value="value"
                    solo>
                  </v-select>
                </div>
                <div class="col-md-4 pb-0 pt-0">
                  <label class="font text-gray" for="">Zip</label>
                  <v-text-field
                    :rules="requiredRules"
                    required
                    solo
                    v-model.trim="location.zipCode">
                  </v-text-field>
                </div>
              </div>
              <div class="col-md-12 pb-0 pt-0">
                <label class="font text-gray" for="">Phone</label>
                <v-text-field
                  :rules="phoneRules"
                  required
                  solo
                  v-model.trim="location.phone">
                </v-text-field>
              </div>
              <div class="row col-md-12">
                <div class="text-gray">
                  Accepting new patients at this location:
                </div>
                <div class="font text-gray ml-4">
                  <div class="d-flex">
                    No
                    <v-switch
                      solo
                      v-model.trim="location.newPatients"
                      class="no-padding ml-2 mr-2 vendors-switch">
                    </v-switch>
                    Yes
                  </div>
                </div>
              </div>
              <br /><br />
              <div class="col-md-12 row" v-if="location.id">
                <a class="text-underline" @click="removeLocation">Remove Location</a>
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-12">
              <div class="col-md-12 pb-0">
                <label class="font" for="">Custom Location URL</label>
                <v-text-field
                  v-model.trim="location.website"
                  solo
                ></v-text-field>
              </div>
              <div class="col-md-12 pb-0">
                <label class="font" for="">Custom Location Description</label>
                <v-textarea
                  v-model.trim="location.description "
                  rows="4"
                  solo
                ></v-textarea>
              </div>
              <div class="col-md-12 pb-0">
                <label class="font font-24" for="">Services</label>
              </div>
              <div class="col-md-12 d-flex pa-0">
                <div class="col-md-4 pb-0">
                <label class="font" for="">Pediatrics</label>
               <span class="d-flex text-gray mt-3"><p class="mt-1 mr-1">No </p> <v-switch @change="changePediatric()" :true-value="true" :false-value="false" v-model="pediatrics" ></v-switch> <p class="mt-1 ml-1">Yes</p> </span> 
              </div>
              <div class="col-md-4 pb-0" v-if="pediatrics == true">
                
                <label class="font" for="">Age</label>
                <v-select
                @change="updateAge"
                v-model="minimumPediatricAge"
                  class="mt-1"
                  :items="numberOptions"
                  style="width: 50%;"
                  solo
                ></v-select>
              </div>
              </div>
            </div>
          </v-form>
        </div>
        <div class="col-md-12 contract-form no-padding" v-if="itemtab == 'tab1'">
          <v-simple-table fixed-header height="600px" id="dpc-locations-providers-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">PROVIDER NAME</th>
                  <th class="text-left">SPECIALTY</th>
                  <th class="text-left">LOCATION(S)</th>
                  <th class="text-left">EDIT</th>
                </tr>
              </thead>
              <tbody v-if="providers && providers.length > 0 && !filterItems">
                <tr v-for="(item, index) in providers" :key="index">
                  <td>
                    <a style="text-decoration: underline; color: #0572f3">
                      {{ item.providerName}}</a>
                  </td>
                  <td class="text-gray">
                    {{ item.specialty }}
                  </td>
                  <td class="text-gray">
                    {{ item.locations }}
                  </td>
                  <td class="text-gray">
                    <a class="text-underline" @click="removeProvider(item)">
                      Remove</a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="filterItems">
                <v-col md="12" cols="12">
                  <v-alert border="bottom" colored-border type="warning" elevation="2"  class="text-gray">
                    Sorry! No results found
                  </v-alert>
                </v-col>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div  class="col-md-12 contract-form no-padding" v-if="itemtab == 'tab2'">
          <v-simple-table fixed-header  height="630px" id="dpc-locations-providers-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">EMAIL</th>
                  <th class="text-left">FIRST NAME</th>
                  <th class="text-left">LAST NAME</th>
                  <th class="text-left">ROLE</th>
                  <th class="text-center">
                    <span class="mr-10">EDIT</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr  v-for="(item, index) in personnel" :key="index"
                  v-if="personnel && personnel.length > 0">
                  <td style="color: #707070">
                    {{ item.email ? item.email : "/" }}
                  </td>
                  <td style="color: #707070">
                    {{ item.firstName ? item.firstName : "/" }}
                  </td>
                  <td style="color: #707070">
                    {{ item.lastName ? item.lastName : "/" }}
                  </td>
                  <td style="color: #707070">
                    {{item.role}}
                  </td>
                  <td class="text-center">
                    <svg
                      @click="removePersonnel(item)"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5327 9.09521L8.625 15.9943"
                        stroke="#DC0101" stroke-width="2.4" stroke-linecap="round"  stroke-linejoin="round"/>
                      <path
                        d="M8.625 9.09521L15.5327 15.9943"
                        stroke="#DC0101" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M17.8328 2.19629H6.31999C3.77664 2.19629 1.71484 4.25549 1.71484 6.79565V18.2941C1.71484 20.8342 3.77664 22.8934 6.31999 22.8934H17.8328C20.3762 22.8934 22.438 20.8342 22.438 18.2941V6.79565C22.438 4.25549 20.3762 2.19629 17.8328 2.19629Z"
                        stroke="#DC0101" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="row col-md-12 text-end justify-end mt-3 mb-1 pr-10" v-if=" itemtab == 'tab0'">
          <div class="">
            <span class="pointer text-underline mr-10"  style="color: #0572f3"
              @click="$router.go(-1)">Cancel</span>
            <span class="pointer text-underline"  style="color: #0572f3"
              @click="submit(itemtab)">Save</span>
          </div>
        </div>
      </div>
      <v-dialog v-model.trim="removeLoc" max-width="450px" persistent>
        <v-card>
          <v-col>
            <h3>Remove Location</h3>
          </v-col>
          <v-col>
            <span>Are you sure you want to delete this Location? </span>
          </v-col>
          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="button-style float-class" @click="closePopUp()">Close</v-btn>
              <v-btn class="blue-buttons float-class" @click="confirmRemove()">Confirm</v-btn>
            </v-card-actions>
          </v-col>
        </v-card>
      </v-dialog>
    </div>
    
    <ProvidersDialog
      :id="location.id"
      :locationProviders="providers"
      v-else-if="showOverlay && providerModal"
      class="background-overlay row"
      ref="providerModal"
      @hideOverlay="hideOverlay"
      @selectedProviders="selectedProviders"
    />
    <PersonnelDialog
      :id="location.id"
      :locationPersonnel="personnel"
      v-else-if="showOverlay && personnelModal"
      class="background-overlay row"
      ref="personnelModal"
      @hideOverlay="hideOverlay"
      @selectedPersonel="selectedPersonel"
    />
    <removeDialog
      ref="dialog_delete"
      v-on:confirm="confirmRemove"
    ></removeDialog>

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";

import ProvidersDialog from "./ProvidersDialog.vue";
import PersonnelDialog from "./PersonnelDialog.vue";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import FullscreenLoader from "../../../Common/FullscreenLoader.vue";
export default {
  components: {
    successModal,
    ProvidersDialog,
    removeDialog,
    PersonnelDialog,
    errorModal,
    VueTimepicker,
    FullscreenLoader
  },
  data() {
    return {
      loader: false,
      pediatrics: false,
      minimumPediatricAge:null,
      services:[],
      numberOptions: Array.from({ length: 19 }, (_, i) => i),
      state: "",
      updated: false,
      removed: false,
      removeLoc: false,
      providers: [],
      providersForAdding: [],
      location: {},
      addNew: false,
      showOverlay: false,
      filterItems: false,
      personnelModal: false,
      providerModal: false,
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      states: [],
      discountRate: "",
      valid: false,
      zipRules: [
        (v) => (v && v.length == 5) || "Zip Code should be five digits long",
      ],
      npiRules: [
        (v) => (v &&v.length == 10) || "NPI Number should be 10 digits long",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      providersDialog: false,
      tab: null,
      href: "#tab-0",
      added: false,
      personnel: [],
      noRedirect: false,
      items: [
        {
          text: "General",
          value: 0,
        },
        {
          text: "Providers",
          value: 1,
        },
        {
          text: "Personnel",
          value: 2,
        },
      ],
      autocomplete: null,
      address1: null,
      debounceTimer: null,
      addressResults: [],
      placeId: '',
    };
  },
  mounted() {
    // this.loadScript();
    // this.initAutocomplete();
},

  computed: {
    itemtab() {
      return this.tab;
    },
  },
  created() {
    this.location.newPatients = true; // DPC 483 | Accepting new patient toggle default should be ON
    this.getStates();
    if(this.$route.params.id){
      this.getLocation();
    }

  },
  methods: { 
    delayFetchAddress(query) {
        // Clear the previous timeout if the user is still typing
        clearTimeout(this.debounceTimer);
        
        // Set a new timeout to call the search after a delay
        this.debounceTimer = setTimeout(() => {
            this.fetchAddress(query);
        }, 500); 
    },
    fetchAddress(query) {
        // Fetch auto complete items
        if (query) {
            api()
                .post(`/dpcproviders/locations/fetchautocomplete`, { query })
                .then((res) => {
                    if (res) {
                        this.addressResults = res.data;
                    }
                })
        } else {
            this.addressResults = [];
        }
    },
    fetchPlace() {
        // Fetch address details
        if (this.placeId) {
            api()
                .post(`/dpcproviders/locations/fetchplace`, { placeId: this.placeId })
                .then((res) => {
                    if (res) {
                        this.location = {
                            ...this.location,
                            ...res.data,
                        }
                    }
                })
        } else {
            this.addressResults = [];
        }
    },
    changePediatric(){
      if(this.pediatrics){
        let data = {
          name : 'Pediatrics',
          serviceType : 'Pediatric',
          serviceTypeCode : 'BH',
          minAge:this.minimumPediatricAge
        }
        this.services.push(data)
      }else{
        this.services = []
      }

    },
    updateAge(){
      // console.log('minimumPediatricAge',this.minimumPediatricAge)
      this.services[0].minAge = this.minimumPediatricAge
    },
    getLocation() {
      this.loader = true;
      api()
        .get(`/dpcproviders/location/` + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.loader = false
            this.location = res.data;
            this.providers = res.data.providers;
            this.filterArray = res.data.providers;
            this.personnel = res.data.personnel;

            //updatePediatrics 
            if(res.data.services.length){
              this.pediatrics = true
              this.minimumPediatricAge = res.data.services[0].minAge
              this.services = res.data.services
            }
          }
        })
        .finally(()=> {
          this.loader = false;
        });
    },
    closePopUp() {
      this.removeLoc = false;
    },
    closeDialog() {
      this.$router.push("/dpc-providers/locations").catch(() => {});
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    openPersonnelDialog() {
      this.showOverlay = true;
      this.providerModal = false;
      this.personnelModal = true;
      if (this.$refs.personnelModal) {
        this.$refs.personnelModal.openDialog();
      }
      // console.log("open", this.personnelModal);
    },
    searchProviders(val) {
      this.providers = this.filterArray.filter((el) => {
        if (
          el.companyName &&
          el.companyName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.companyName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el.providerName &&
          el.providerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return el.providerName.toLowerCase().includes(val.toLowerCase());
        } else if (
          el["Provider First Name"] &&
          el["Provider First Name"].toLowerCase().includes(val.toLowerCase())
        ) {
          return el["Provider First Name"]
            .toLowerCase()
            .includes(val.toLowerCase());
        } else if (
          el["Provider Last Name (Legal Name)"] &&
          el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return el["Provider Last Name (Legal Name)"]
            .toLowerCase()
            .includes(val.toLowerCase());
        }
      });
      if (this.providers.length < 1) {
        this.filterItems = true;
      }
    },
    getPersonnel() {
      api()
        .get("/dpcproviders/users?locations=" + this.location._id)
        .then((res) => {
          if (res) {
            this.personnel = res.data;
            this.$store.commit("setPersonnel", res.data);
          }
        });
    },
    confirmRemove() {
      api()
        .delete("/dpcproviders/locations/" + this.location.id)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Location removed successfully");
            this.removeLoc = false;
            this.removed = true;
          }
        });
    },
    removePersonnel(item) {
      // console.log('item',item)
      api()
        .delete(`/dpcproviders/location/${this.$route.params.id}/user/${item.id}`)
        .catch((err) => {
          console.log("removePersonnel() Error: ", err)
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Personnel removed successfully");
            this.getLocation();
          }
        });
    },
    removeLocation() {
      this.removeLoc = true;
    },
    getAllProviders() {
      api()
        .get("/dpcproviders/providers")
        .then((res) => {
          if (res.data.length > 0) {
            this.providers = [];
            this.filterArray = [];
            res.data.forEach((el) => {
              el.locations.forEach((loc) => {
                if (loc == this.location._id) {
                  this.providers.push(el);
                  this.filterArray.push(el);
                }
              });
            });
          }
        });
    },
    selectedPersonel(items) {
      // items.forEach((item) => {
      //   this.personnel.push(item);
      // });
      // this.showOverlay = false;
      this.getLocation();
    },
    selectedProviders(items) {
      this.getLocation();
      // items.forEach((item) => {
      //   this.providers.location = item.locations.length
      //   this.providers.push(item);
      //   //        this.filterArray.push(item)
      // });
      // this.showOverlay = false;
    },
    removeProvider(item) {
      this.noRedirect = false;
      // console.log('this',item)
      if (this.$route.params.id) {
        api()
          .delete(`/dpcproviders/location/${this.$route.params.id}/provider/${item.id}`)
          .catch((err) => {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp("There was an error, system admin is notified. Please try again later.");
            } else {
              this.$refs.error_modal.openPopUp(err.response.data);
            }
          })
          .then((res) => {
            if (res) {
              this.noRedirect = true;
              this.$refs.success_modal.openPopUp("Provider removed successfully");
              this.getLocation();
            }
          });
      } else {
        this.providers.splice(this.providers.indexOf(item), 1);
        this.filterArray = this.providers;
      }
    },
    closeModal() {
      if (this.$refs.providerModal) {
        this.$refs.providerModal.openDialog();
      }
    },
    hideOverlay() {
      //  this.$router.push(`/dpc-providers/locations/location/{${this.$router.params.id}`).catch(()=>{});
      //  this.getAllProviders();
      this.showOverlay = false;
    },
    hideOverlay1() {
      this.$router.push("/dpc-providers/providers");
      //  this.getAllProviders();
      this.showOverlay = false;
    },
    openProviderDialog() {
      this.showOverlay = true;
      this.providerModal = true;
      if (this.$refs.providerModal) {
        this.$refs.providerModal.openDialog();
      }
    },
    closeDialog1() {
      this.providerModal = false;
    },
    backTab(tab) {
      if (tab == "tab1") {
        this.tab = "tab0";
      } else if (tab == "tab2") {
        this.tab = "tab1";
      }
    },
    submit(tab) {
      if(tab == 'tab0'){
        if (this.$route.params.id) {
        this.updateLocation();
      } else {
         this.createLocation();
      }
      }
    },
    createLocation(){
      let data = {
            name: this.location.name,
            address1: this.location.address1,
            address2: this.location.address2,
            city: this.location.city,
            state: this.location.state.value ? this.location.state.value : this.location.state,
            zip: this.location.zipCode,
            phone: this.location.phone,
            newPatients: this.location.newPatients,
            providers: this.providers.length > 0 ? this.providers : [],
            personnel: this.personnel.length > 0 ? this.personnel : [],
            NPI: this.location.NPI ? this.location.NPI : "",
            newPatients: this.location.newPatients ? true : false,
            website:this.location.website ? this.location.website : '',
            description:this.location.description ? this.location.description: '',
            services:this.services
            // timeZone: this.location.timeZone,
          }
        this.$refs.locationRef.validate();
        if (this.valid) {
            this.loader = true;
          api()
            .post("/dpcproviders/location", data)
            .catch((err) => {
                this.loader = false;
              if (err.response.status == 500) {
                this.$refs.error_modal.openPopUp(
                  "There was an error, system admin is notified. Please try again later."
                );
              } else {
                this.$refs.error_modal.openPopUp(err.response.data);
              }
            })
            .then((res) => {
                this.loader = false;
              if (res) {
                // this.locationID = res.data[res.data.length - 1];
                this.$refs.success_modal.openPopUp("Location Added successfully!");
                this.added = true;
              }
            });
        } else {
          this.$refs.error_modal.openPopUp("Missing required fileds");
        }
    },
    nextTab(tab) {
      if (tab == "tab0") {
        this.tab = "tab1";
      } else {
        this.tab = "tab2";
      }
    },
    updateLocation() {
      // console.log('this.location.state',this.location.state)
      let validate = this.$refs.locationRef.validate();
        if (validate) {
            this.loader = true;

          let data = {
            name: this.location.name,
            NPI: this.location.NPI ? this.location.NPI : "",
            address1: this.location.address1,
            address2: this.location.address2,
            city: this.location.city,
            state: this.location.state.value,
            zip: this.location.zipCode,
            phone: this.location.phone,
            newPatients: this.location.newPatients ? true : false,
            website:this.location.website ? this.location.website : '',
            description:this.location.description ? this.location.description: '',
            services:this.services
            // providers: this.providers.length > 0 ? this.providers : [],
            // personnel: this.personnel.length > 0 ? this.personnel : [],
          };
          // console.log('data',data)
          api()
            .put("/dpcproviders/locations/" + this.$route.params.id, data)
            .catch((err) => {
                this.loader = false;
              if (err.response.status == 500) {
                this.$refs.error_modal.openPopUp("There was an error, system admin is notified. Please try again later.");
              }
              else if (err.response.status == 403) {
                this.$refs.error_modal.openPopUp(err.response.data.message);
              }
              else {
                this.$refs.error_modal.openPopUp(err.response.data);
              }
            })
            .then((res) => {
                this.loader = false;
              if (res) {
                this.$refs.success_modal.openPopUp("Location Updated successfully!");
                this.updated = true;
              }
            });
        } else {
          this.$refs.error_modal.openPopUp("Missing required fileds");
        }
    },
    getStates() {
      api()
        .get("/api/states")
        .then((res) => {
          res.data.forEach((el) => {
            if (el.active) {
              this.states.push(el);
            }
          });
        });
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      if (this.removed == true || this.updated == true || this.added) {
        this.$router.push("/dpc-providers/locations").catch(() => {});
      } else {
        this.$router
          .push(`/dpc-providers/locations/location/${this.$route.params.id}`)
          .catch(() => {});
      }
    },
  },
};
</script>
<style>
.postion-location-relative {
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
.background-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

#dpc-locations-providers-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  height: 80px !important;
}
</style>
