<template>
    <div>
        <v-overlay v-if="waitingForResponse" v-model="waitingForResponse" contained class="align-center justify-center">
            <v-progress-circular indeterminate :size="100" color="#326ab3" class="text-center mt-10 mb-5">
                <img src="/newapalylogoonlyblue.png" height="70" class="img-mobile" />
            </v-progress-circular>
        </v-overlay>
      <div v-else class="clinic_main_content">
        <!-- MAIN INFO -->
        <div class="clinic_main_info"> 
  
          <div class="clinic_header_div">
            <!-- BACK BUTTON -->
            <div class="clinic_row clinic_row_extra">
              <!-- BACK BUTTON -->
              <div class="clinic_header_div">
                <div @click="$router.go(-1)" role="button" class="mt-2 display_flex display_flex_mid" >
                  <img src="/svg/backArrow.svg" width="16" />
                  <p class="ma-0 ml-2">Back</p>
                </div>
              </div>
              <!-- BADGE -->
              <div class="badge_n_share">
                <div class="clinic_header_div display_flex display_flex_mid display_flex_right text_nowarp">
                    <span v-if="provider.favorite" class="d-flex"> <p>Remove from favorites</p> <img @click="favorite()"
                            src="/svg/filled-heart.svg" height="21" class="ml-1" role="button" /></span>
                    <span v-else class="d-flex "> <p>Add to favorites</p>  <img @click="favorite()"
                            src="/svg/empty-heart.svg" height="21" class="ml-1 " role="button" /></span>
                </div>
              </div>
            </div>
            <div class="green-header">
</div>
            <div class="clinic_card_header">
              <!-- LOGO -->
              <div class="clinician-card-logo" :style="`background-image:url(${returnCardImage(provider)})`"></div>
              &emsp;
              <!-- DPC Name -->
              <div class="clinic-item-content-name card_title">
                <span class="pt-2">
                     {{ returnCapitalizedName(provider.companyName)  }}  
                </span>
                
                    <div class="mt-3 mb-2">
                        <v-chip class="pa-2" color="#F2F2F2" text-color="#333333" small>
                            <img src="/svg/newClinic.svg" class="mr-1" />
                            Clinic & Organization
                        </v-chip>
                    </div>
                    <div class="d-flex pt-1 pb-1">
                        <span class="d-flex mr-4"> <img class="mr-2 mt-1" height="16" width="16"
                                src="/svg/advancedPrimaryCare.svg" />
                            <p class="ma-0 font-14"> {{ provider.category }}</p>
                        </span>

                    </div>
                    <div class="d-flex pt-1">
                        <span class="d-flex mr-4"> <img class="mr-2 mt-1" height="16" width="16"
                                src="/svg/newLocation.svg" />
                            <p class="ma-0 font-14"> 0 locations</p>
                        </span>
                        <span class="d-flex"> <img class="mr-2 mt-1" height="16" width="16" src="/svg/activeMembers.svg" />
                            <p class="ma-0 font-14"> 0 active members</p>
                        </span>

                    </div>
              </div>
              <div v-if="showInfo" style="border: 2px solid #999999" class="radius-8 pa-3 ml-md-16">
                <span >
                    <img class="mr-2 mt-1" height="16" width="16" src="/svg/info.svg" />
                    To activate a solution for a client, go to the client's profile page <v-icon @click="hideInfo()">mdi-close</v-icon>
                </span>
              </div>
            </div>
  
  
  
            <!-- CONFIRMATION BUTTON -->
            <!-- <div class="profile_row" v-if="provider && provider.newPatients && homeInfo &&  homeInfo.subscription && !homeInfo.subscription.status == 'pending'">
              <div class="profile_badge_container display_flex display_flex_mid" @click="goToRegister()">
                  <img src="/svg/dollar_badge.svg" width="35" height="auto" class="mr-3"/>
                  <p class="mb-0"> Select this clinic and then we'll confirm that your employer pays for this benefit</p>
                  <img src="/svg/chevron_right.svg" width="35" height="auto" class="mr-3"/>
              </div>
            </div> -->
  
          </div>
  
          <!-- LEFT SIDE -->
          <!-- <div class="profile_header_div profile_mobile_hide" style="display: none">
          </div> -->
  
        </div>
  
        <!-- TABS -->
        <div class="clinic_main_row">
          <br> <!-- SPACER -->
          <div class="clinic_row">
            <div class="clinic_tab_con display_flex display_flex_mid">
              <div class="clinic_tab_div text_nowarp" v-for="(tab, t) in profileTab" :key="'tab'+t">
                <div :class="`clinic_tab ${tab.status == 'open'? 'clinic_tab_active':''}`" @click="goToSection(tab)">
                  {{ tab.tabName }}
                </div>
              </div>
            </div>
          </div>
          <div class="clinic_line"></div>  <!-- LINE -->
          <br> <!-- SPACER -->
        </div>
  
        <!-- DECRIPTION -->
        <div class="clinic_main_row mb-10 pl-8">
          <div class="clinic_info_title mb-2">Description:</div>
  
          <!-- DISPLAYING LESS -->
          <div v-if="descLength" class="clinic_info_text clinic_desc_container mb-0 pb-0" :style="descLength ? 'height:auto' : ''">
              {{ provider.details?.description.substring(0,200) }}...
              <br>
              <small class="clinic_display_btn" @click="descLength = false">
                show more <v-icon small color="primary">mdi-chevron-down</v-icon>
              </small>
          </div>
  
          <!-- DISPLAYING MORE -->
          <div v-else-if="!descLength && provider.details?.description!=null && provider.details?.description.length > 300 " class="clinic_info_text clinic_desc_container" style="height:auto">
              {{ provider.details?.description }}
              <br>
              <small class="clinic_display_btn" @click="descLength = true">
                show less <v-icon small color="primary">mdi-chevron-up</v-icon>
              </small>
            </div>
  
          <!-- NORMAL DISPLAY -->
          <div v-else class="clinic_info_text clinic_desc_container" style="height:auto">
            {{ provider.details && provider.details.description ? provider.details.description : 'No Description' }}
          </div>
        </div>
  
        <!--CONTACT INFO AND MAP -->
        <div id="tab_contacts" class="clinic_main_info mb-10 pl-8 pr-8">
          <!-- DECRIPTION -->
          <div class="clinic_header_div">
            <div class="clinic_info_title mb-2">Contact Information</div>
  
            <!-- WEBSITE --> 
            <div class="clinic_icon_text clinic_cursor">
              <div class="display_flex display_flex_mid clinic_color_primary mb-2" @click="openWebsite(provider.details.website)">
                Visit website &nbsp; <img src="/svg/visitWeb.svg" class="ml-1" height="18"/>
              </div>
            </div>
  
            <!-- PHOME NUMBER -->
            <div class="clinic_row_2">
              <div class="clinic_info_subtitle mb-1">Phone Number:</div>
              <div class="clinic_info_text" style="height:auto">
                {{ provider.contact?.phone }}
              </div>
            </div>
  
            <!-- ADDRESS -->
            <div class="clinic_row_2">
              <div class="clinic_info_subtitle mb-1">Address:</div>
              <div class="clinic_info_text" style="height:auto">
                {{ provider.contact?.address1 }}&nbsp;{{ provider.contact?.city }},&nbsp;{{ provider.contact?.state }}&nbsp;{{ provider.contact?.zipCode }}
              </div>
            </div>
            
            <!-- DISTANCE -->
            <div class="clinic_row_2" v-if="provider.entity == 'location' && provider.distance != null">
              <div class="clinic_info_subtitle mb-1">{{ distance_format(provider.distance) }} miles from you</div>
            </div>
          </div>
  
          <!-- MAP & CONTACTS -->
          <div class="clinic_header_div">
  
            <!-- MAP -->
            <div class="clinic_row_2 clinic_map mt-0">
              <GmapMap :center="userCenter" :zoom="18" class="clinic_map_size">
          
                <GmapMarker 
                  :position="userCenter"
                  @click="openCard(userCenter)"
                  :icon="{ url: '/dark-blue.png', scaledSize: { width: 40, height: 60 } }"
                >
                </GmapMarker>
  
                <GmapInfoWindow :position="infoWindowPosition"  :opened="infoWindowOpened"  @closeclick="closeInfoWindow">
                  <!-- <v-card class="profile_map_window">
                      <div class="profile_map_card">{{ returnCapitalizedName(provider.companyName) }}</div>
                  </v-card> -->
                  <div class="card_layout_map" :style="resolveMarketplaceBackgroundColor(provider)">
                    <!-- CARD -->
                    <v-card v-if="provider" height="auto"  width="250" class="card-radius v_card_height no_box_shadow">
  
                      <!-- <div class="marketplace-item tester"> -->
                      <div class="no_box_shadow">
  
                        <!-- CARD HEADER -->
                        <div class="market_card_header_map">
  
                          <!-- LOGO -->
                          <div class="card_logo_map" :style="`background-image:url(${returnCardImage(provider)})`"></div>
  
                          &emsp;
  
                          <!-- DPC Name -->
                          <!-- <div class="marketplace-item-content-name" :class="!loggedIn ? 'pt-8' : 'pt-4'"> -->
                          <div class="clinic-item-content-name card_title_map">
                              {{ returnCapitalizedName(provider.companyName) }}
                              <span v-if="provider.credentials">, {{ provider.credentials }}</span>
                          </div>
  
                        </div>
  
                        <div class="card_info_container_map">
                          <div class="card_info_icontexts_map">
                            <!-- ADDRESS -->
                            <div v-if="provider.entity == 'location'" class="clinic-item-content-address pt-4 card_icon_text">
                              <img class="mr-2 card_icon_map" src="/svg/cardLocation.svg" />
                              <p class="ma-0 card_text_map">
                                {{ provider.address }}&nbsp;{{ provider.city }},&nbsp;{{ provider.state }}&nbsp;{{ provider.zipCode }}
                              </p>
                            </div>
  
                            <div v-show="provider.entity == 'location'">
  
                              <!-- APPOINTMENT -->
                              <div class="clinic-item-content-category pt-4 card_icon_text">
                                <img class="mr-2 card_icon_map" src="/svg/calendar_alt.svg" />
                                <p class="ma-0 card_text card_text_map"> Next day appointments typically available.</p>
                              </div>
  
                              <!-- PROFILE EXTRA INFO -->
                              <div class="clinic-item-content-category pt-4 card_icon_text">
                                <img class="mr-2 card_icon_map" src="/svg/map_marker_smile.svg" />
  
                                <!-- SEE IF card.name HAS "NICE" -->
                                <!-- <p class="ma-0 card_text card_text_map">{{ String(returnClinicanName(provider.name)).includes("Nice") ? 'In Home Care;':'At-Clinic;'}} Virtual; Text/Chat; Phone; At Work</p> -->
                                <p class="ma-0 card_text card_text_map">{{ provider.homeCare == true ? 'In Home Care;':'At-Clinic;'}} Virtual; Text/Chat; Phone; At Work</p>
                              </div>
                              
                            </div>
  
                          </div>
  
                        </div>
  
                      </div>
  
                    </v-card>
                  </div>
                </GmapInfoWindow>
  
              </GmapMap>
            </div>
            <div @click="getDirections()" class="clinic_cursor display_flex display_flex_mid clinic_color_primary mb-2 mt-2" >
              Get directions on Google Maps &nbsp; <img src="/svg/chevron_right.svg" class="ml-1" height="18"/>
            </div>
  
            <!-- {{ provider }} -->
  
          </div>
  
        
        </div>
  
        <!-- SERCVICES -->
        <div id="tab_services" class="clinic_main_row mb-10 pl-8 pr-8">
          <div class="clinic_info_title mb-2">Services</div>
          <div class="clinic_category_container">
  
            <!-- CORE SERVICES OFFERED -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_2">
                <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Core Services Offered
              </div>
              <div class="mt-2">
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Wellness Checks and Preventative Care</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Chronic Disease Management</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">24/7 Access to Manage your Care </p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Sick Care </p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Mental Health Guidance </p>
                </div>
              </div>
            </div>
  
            <!-- ADDITIONAL COVERED SERVICES -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_2">
                <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Additional Covered Services
              </div>
              <div class="mt-2">
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Nutritionists</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Pharmacy</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Flu Shots and Select Vaccines</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Pediatrics</p>
                </div>
              </div>
            </div>
  
            <!-- OTHER SERVICES AVAILABLE -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_2">
                <img src="/svg/heart_circle.svg" width="40" height="auto" /> &nbsp; Other Services Available  
              </div>
              <div class="mt-2">
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Imaging</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Physical Therapy</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Cardiac testing</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Pulmonary Studies</p>
                </div>
                <div class="display_flex display_flex_mid">
                  <img src="/svg/check.svg" width="25" height="auto" class="mr-1"/> &ensp; 
                  <p class="mb-0">Respiratory Therapy</p>
                </div>
              </div>
            </div>
  
            
  
          </div>
        </div>
  
        <!-- CARE MODALITY -->
        <div class="clinic_main_row mb-10 pl-8 pr-8">
          <div class="clinic_info_title mb-2">Care Modality</div>
  
          <!-- CORE SERVICES OFFERED -->
          <div class="clinic_category_container">
  
            <!-- AT-CLINIC -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_3">
                <img class="mr-2 clinic_modality_icon" :src="`/svg/${provider.homeCare == true ? 'home_heart.svg':'hospital.svg'}`"/>
                <div class="clinic_modality_content">
                  <!-- At-Clinic -->
                  {{ provider.homeCare == true ? 'In Home Care':'At-Clinic'}}
                  <br>
                  <div class="clinic_modality_text">
                    <!-- Visit your provider face-to-face at the clinic -->
                    {{ provider.homeCare == true ? 'We come and see you at home':'Visit your provider face-to-face at the clinic'}}
                  </div>
                </div>
              </div>  
            </div>
  
            <!-- VIRTUAL -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_3">
                <img class="mr-2 clinic_modality_icon" src="/svg/laptop.svg"/>
                <div class="clinic_modality_content">
                  Virtual
                  <br>
                  <div class="clinic_modality_text">
                    Meet through a video visit
                  </div>
                </div>
              </div>  
            </div>
  
            <!-- TEXT/CHAT -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_3">
                <img class="mr-2 clinic_modality_icon" src="/svg/chat.svg"/>
                <div class="clinic_modality_content">
                  Text/Chat
                  <br>
                  <div class="clinic_modality_text">
                    Chat/Text with your provider
                  </div>
                </div>
              </div>  
            </div>
  
            <!-- PHONE -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_3">
                <img class="mr-2 clinic_modality_icon" src="/svg/telephone.svg"/>
                <div class="clinic_modality_content">
                  Phone
                  <br>
                  <div class="clinic_modality_text">
                    Talk on the phone
                  </div>
                </div>
              </div>  
            </div>
  
            <!-- AT WORK -->
            <div class="clinic_cont_div">
              <div class="clinic_icon_text_3">
                <img class="mr-2 clinic_modality_icon" src="/svg/briefcase.svg"/>
                <div class="clinic_modality_content">
                  At Work
                  <br>
                  <div class="clinic_modality_text">
                    See your provider at work (when they're there)
                  </div>
                </div>
              </div>  
            </div>
  
  
          </div>
        </div>
  
        <!-- PROVIDERS -->
        <div id="tab_providers" :class="`clinic_providers_layout ${provider && !provider.newPatients ? 'extra_margin_joinwaitlist':'extra_margin_newpatient'}`" class="pl-8" >
  
          <div class="clinic_info_title mb-2">Clinicians</div>
  
          <div class="" v-if="provider.clinicians.length <= 0"> 
            Clinic <strong>{{ returnCapitalizedName(provider.companyName) }}</strong> has not listed their providers yet
          </div>
  
          <!-- SAMPLE PROVIDER -->
          <div style=" ">
  
          <div class="clinic_provider_card_layout" v-for="(clinician, c) in provider.clinicians" :key="'c'+c">
            <div class="clinic_provider_card_header">
              <div class="clinic_provider_card_image"  v-if="clinician.gender == 'F'"  :style="`background-image:url('/clinician_f.png')`">
              </div>
              <div class="clinic_provider_card_image" v-else-if="clinician.gender == 'M'" :style="`background-image:url('/clinician_m.png')`">
              </div>

              &emsp;
              <div class="clinic_provider_card_name">
                  {{ clinician.providerName }}
              </div>
            </div>
            <div class="provider_icon_text">
              <img class="mr-2" width="20px" height="auto" src="/svg/hospital.svg"/>
              &nbsp; {{ clinician.category }}
            </div>
            <div class="provider_icon_text">
              <img class="mr-2" width="20px" height="auto" :src="clinician.gender == 'F' ? '/svg/femaleClinician.svg' : '/svg/maleClinician.svg'"/>
              &nbsp; {{ clinician.gender == 'F' ? 'Female':'Male' }}
            </div>
            <div class="clinic_icon_text_3">
              <div class="provider_title">
                Works At
              </div>
              <div class="provider_texts">
                <div class="clinic_modality_text">
                  {{ clinician.worksAt }}
                </div>
              </div>
            </div> 
            <div class="provider_buttons">
              <button class="provider_button" style="visibility: hidden;">Select</button>
              <button class="provider_button" @click="goToDetails(clinician, 'provider')" >View Details</button>
            </div>
          </div>
          </div>
  
        </div>
      </div>
  
      </div>
  </template>

  <script>
  var _paq = window._paq || [];
  _paq.push(["trackPageView"]);
  import api from "../../../../services/api";
  import { mapState } from 'vuex';
  
  export default {
    components: {
    },
    props: {},
    data() {
      return {
        showInfo: true,
        waitingForResponse: false,
        noEmail: false,
        selectingProvider: false,
        errorMessage: "",
        selectedProvider: [],
        selectedMember: [],
        userInfo: [],
        notifyMe: false,
        addedToWaitlist: false,
        allowApalyToContact: false,
        selectedLocationForWaitlist: "",
        profileTab: [
          { tabName: "Info", tabID: "tab_info", status: "open" },
          { tabName: "Contact Information", tabID: "tab_contacts", status: "close" },
          { tabName: "Services", tabID: "tab_services", status: "close" },
          { tabName: "Clinicians", tabID: "tab_providers", status: "close" },
        ],
        provider: null,
        hasPediatric: false,
        tab: "info",
        slectedMessage: "",
        descLength: false,
        userLat: null,
        userLong: null,
        center: {
          lat: 0,
          lng: 0
        },
        userCenter: {
          lat: 0,
          lng: 0
        },
        places: [],
        infoWindowOpened: false,
      infoWindowPosition: null,
      infoWindowContent: {
        name: "",
        description: "",
      },
      selectedPlace: null,
      centerData: {
          name: "Struga",
          description: "your address ",
        },
        card: null,
    };
    },
    mounted() {
    //   if (typeof google === 'undefined') {
    //   const timer = setInterval(() => {
    //     if (typeof google !== 'undefined') {
    //       clearInterval(timer); 
    //       this.setUpGoogleMaps(); 
    //     }
    //   }, 100);
    // } else {
    //   this.setUpGoogleMaps();
    // }
  
  },
    created() {
      this.getProvider();

    },
    computed: {
    },
    methods: {
        hideInfo(){
            this.showInfo = false
        },
   
      resolveMarketplaceBackgroundColor(card) {
        if(card){
          if (card.entity == "location") {
            return "border-left: 15px solid #172E4D !important; border-radius: 8px 0 0 8px !important";
          } else {
            return "border-left: 15px solid #249999 !important; border-radius: 8px 0 0 8px !important";
          }
        }
      },
      returnClinicanName(inputString){
        if(inputString){
          const words = inputString.split(' ');
          const capitalizedWords = words.map(word => {
            if (word.length === 0) return word;
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          });
          const capitalizedString = capitalizedWords.join(' ');
          return capitalizedString;
        }
          
      },
      goToDetails(item, entity) {
        if (entity == "solution") {
          this.$router.push(`/dpc-providers/solutions/details/${item.id}`);
        } else if (entity == "location") {
          this.$store.state.documentTitle = item.name;
          this.$router.push({
            path: `/dpc-providers/solutions/provider/details/${item.id}`,
            query: { location: item.locationId },
          });
        } else if (entity == "vendor") {
          this.$router.push(`/dpc-providers/solutions/vendor/details/${item.id}`);
        } else if (entity == "provider") {
          this.$router.push(`/dpc-providers/solutions/clinician/details/${item.id}`);
        }
        this.showOverlay = false;
      },
      goToSection(tab) {
    for (let i = 0; i < this.profileTab.length; i++) {
      this.profileTab[i].status = 'close';
    }
    const indx = this.profileTab.findIndex(obj => obj.tabID === tab.tabID);
    if (indx !== -1) {
      this.profileTab[indx].status = "open";
      const targetElement = document.getElementById(this.profileTab[indx].tabID);
      const targetOffsetTop = targetElement.offsetTop - 20;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: 'smooth'
      });    }
  },
  getDirections() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            var fromLat = position.coords.latitude;
            var fromLng = position.coords.longitude;

            var toLat = this.provider.contact.coordinates.lat;
            var toLng = this.provider.contact.coordinates.lng;

            console.log(fromLat, fromLng, toLat, toLng);
            window.open(`https://www.google.com/maps/dir/${fromLat},${fromLng}/${toLat},${toLng}`, '_blank');
        }, error => {
            console.error("Error getting user's location:", error);
        });
    } else {
        console.error("Geolocation is not supported by this browser.");
    }
},
      openWebsite(website) {
        if(website.toLowerCase().includes('http')) {
          window.open(`${website}`, '_blank')
        }
        else {
          window.open(`https://${website}`, '_blank')
        }
      },
      returnCapitalizedName(inputString){
        if(inputString) {
          const words = inputString.split(' ');
          const capitalizedWords = words.map(word => {
            if (word.length === 0) return word;
           return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          });
          const capitalizedString = capitalizedWords.join(' ');
          return capitalizedString;
        }
      },
      goToRegister() {
//nothing
    },
      changeTab(tab) {
        this.tab = tab;
      },
      getProvider() {
        this.waitingForResponse = true;
        let params = "";
        if (this.$route.query.location) {
          params = `?locationId=${this.$route.query.location}`;
        }
    
          api()
            .get(`/dpcproviders/market/provider/${this.$route.params.id}` + params)
            .then((res) => {
              if (res) {
                this.provider = res.data;
                this.waitingForResponse = false;
                console.log('res.data.contact', res.data.contact);
                if(res.data && res.data.details && res.data.contact && res.data.contact.coordinates){
                  let coord = res.data.contact.coordinates
                  this.userCenter = { lat: parseFloat(coord.lat), lng: parseFloat(coord.lng) }
                }
                else this.geocodeLocation(this.provider);
                if(res.data.details && res.data.details.description != null && res.data.details?.description.length > 300){
                  this.descLength = true;
                  
                }
                else{
                  this.descLength = false;
                }
                if (this.provider.services) {
                  this.hasPediatric = this.provider.services.some(
                    (item) => item.serviceType == "Pediatric"
                  );
                }
                this.provider.logo = res.data.logo ? res.data.logo : "";
              }
            });
        
      },
      geocodeLocation(item) {
        const address = `${item.contact.address1} ${item.contact.city}, ${item.contact.state} ${item.contact.zipCode}`

        api()
            .post(`/dpcproviders/locations/geocode`, { address })
            .then((res) => {
                if (res && res.data) {
                    const { lat, lng } = res.data.results[0].geometry.location;
                    item.latitude = lat;
                    item.longitude = lng;
                    const placeItem = { item, location: data.results[0].geometry.location };
                
                    this.userCenter = data.results[0].geometry.location;
                }
            })
            .catch((error) => {
                console.error('Geocoding error:', error);
            });
    },
      openCard(place){
        if(place.location){
          this.infoWindowPosition = place.location;
        }else{
          this.infoWindowPosition = place;
        }
  
        this.infoWindowContent = place;
        if (place && place.item) {
          this.card = place.item;
        } else {
          this.card = null;
        }
        this.infoWindowOpened = true;
      },
      closeInfoWindow() {
        this.infoWindowOpened = false;
        this.selectedPlace = null; 
      },
      backToMarketplace() {
        this.$router.push("/dpc-providers/solutions");
      },
      favorite() {
      let data = {
        entity: 'location',
        location: this.$route.query.location,
        favorite: !this.provider.favorite,
      };
      api()
        .post("/dpcproviders/market/favorite", data)
        .then((res) => {
          if (res) {
            this.provider.favorite = !this.provider.favorite;
          }
        });
    },
      returnCardImage(provider) {
        if (provider && provider.logo && provider.logo != "") {
          return provider.logo;
        } else {
          if (provider && provider.type == "Clinician") {
            if (provider && provider.gender == "M") {
              return "/svg/clinicianCardMale.svg";
            } else {
              return "/svg/clinicianCard.svg";
            }
          } else {
            return "/tpa/clinicsAndOrganizations.svg";
          }
        }
      },
     
    },
  };
  </script>
  <style scoped lang="scss">
  
 </style>